import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { getRefresh, signIn, isAuthenticated } from '../apis/api';

interface AuthContextType {
  isSignedIn: boolean;
  setIsSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
  signOut: () => void;
  signInUser: (username: string, password: string) => Promise<any>;
  refreshToken: () => Promise<void>;
  checkAuthStatus: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

  const [isSignedIn, setIsSignedIn] = useState<boolean>(
    isAuthenticated() ? true : false
  );
  console.log(isSignedIn);

  const checkAuthStatus = () => {
    const isAuth = isAuthenticated();
    setIsSignedIn(isAuth);
    return isAuth;
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  useEffect(() => {
    if (!isSignedIn) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('session-exp');
      localStorage.removeItem('access-token-exp');
      localStorage.removeItem('refresh-token-exp');
    }
  }, [isSignedIn]);

  const signOut = () => {
    setIsSignedIn(false);
    localStorage.clear();
  };

  const signInUser = async (username: string, password: string) => {
    try {
      const data = await signIn(username, password);
      if (data.status === 'success') {
        setIsSignedIn(true);
        localStorage.setItem('session-exp', (Date.now() + 60 * 60 * 1000).toString());
        localStorage.setItem('refreshToken-exp', (Date.now() + 60 * 60 * 24 * 1000).toString());
        return data;
      } else {
        throw new Error(data.message || 'Sign in failed');
      }
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    }
  };

  const refreshToken = async () => {

    const sessionExp = localStorage.getItem('session-exp');
    const refreshTokenExp = localStorage.getItem('refreshToken-exp');

    if (sessionExp && sessionExp < Date.now().toString()) {
      signOut();
      return;
    } else if (refreshTokenExp && refreshTokenExp < Date.now().toString()){
        signOut();
        return;
    } else if (sessionExp && refreshTokenExp) {
      return;
    }

    try {
      const data = await getRefresh();
      if (data.statue === "success") {
        setIsSignedIn(true);
        localStorage.setItem('session-exp', (Date.now() + 60 * 60 * 1000).toString());
      } else {
        throw new Error('Token refresh failed');
      }
    } catch (error) {
      console.error('Token refresh error:', error);
      setIsSignedIn(false);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ isSignedIn, setIsSignedIn, signOut, signInUser, refreshToken, checkAuthStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};