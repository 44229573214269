import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useColorMode } from '@chakra-ui/react';

interface ColorContextType {
    lightModePrimaryColor: string;
    lightModeSecondaryColor: string;
    lightModeTextColor: string;
    lightModeGradientColor: string;
    darkModePrimaryColor: string;
    darkModeSecondaryColor: string;
    darkModeTextColor: string;
    darkModeGradientColor: string;
    setLightModePrimaryColor: (color: string) => void;
    setLightModeSecondaryColor: (color: string) => void;
    setLightModeTextColor: (color: string) => void;
    setLightModeGradientColor: (color: string) => void;
    setDarkModePrimaryColor: (color: string) => void;
    setDarkModeSecondaryColor: (color: string) => void;
    setDarkModeTextColor: (color: string) => void;
    setDarkModeGradientColor: (color: string) => void;
}

const ColorContext = createContext<ColorContextType | undefined>(undefined);

export const useColorContext = () => {
    const context = useContext(ColorContext);
    if (context === undefined) {
        throw new Error('useColorContext must be used within a ColorProvider');
    }
    return context;
};

interface ColorProviderProps {
    children: ReactNode;
}

export const ColorProvider: React.FC<ColorProviderProps> = ({ children }) => {
    // Light mode colors
    const [lightModePrimaryColor, setLightModePrimaryColor] = useState('#F0F4F8');
    const [lightModeSecondaryColor, setLightModeSecondaryColor] = useState('#E1E8ED');
    const [lightModeTextColor, setLightModeTextColor] = useState('#2C3E50');
    const [lightModeGradientColor, setLightModeGradientColor] = useState('linear-gradient(45deg, #4E44CE, #21D4FD)');

    // Dark mode colors
    const [darkModePrimaryColor, setDarkModePrimaryColor] = useState('#1A2639'); // 짙은 군청색
    const [darkModeSecondaryColor, setDarkModeSecondaryColor] = useState('#2A3F5F'); // 중간 톤의 군청색
    const [darkModeTextColor, setDarkModeTextColor] = useState('#E6E8E9'); // 매우 연한 회색
    const [darkModeGradientColor, setDarkModeGradientColor] = useState('linear-gradient(45deg, #FF3CAC, #784BA0)');

    const { colorMode } = useColorMode();

    const value = {
        lightModePrimaryColor,
        lightModeSecondaryColor,
        lightModeTextColor,
        lightModeGradientColor,
        darkModePrimaryColor,
        darkModeSecondaryColor,
        darkModeTextColor,
        darkModeGradientColor,
        setLightModePrimaryColor,
        setLightModeSecondaryColor,
        setLightModeTextColor,
        setLightModeGradientColor,
        setDarkModePrimaryColor,
        setDarkModeSecondaryColor,
        setDarkModeTextColor,
        setDarkModeGradientColor,
    };

    return <ColorContext.Provider value={value}>{children}</ColorContext.Provider>;
};

export const useCurrentColor = () => {
    const { colorMode } = useColorMode();
    const colors = useColorContext();

    return {
        primaryColor: colorMode === 'light' ? colors.lightModePrimaryColor : colors.darkModePrimaryColor,
        secondaryColor: colorMode === 'light' ? colors.lightModeSecondaryColor : colors.darkModeSecondaryColor,
        textColor: colorMode === 'light' ? colors.lightModeTextColor : colors.darkModeTextColor,
        gradientColor: colorMode === 'light' ? colors.lightModeGradientColor : colors.darkModeGradientColor,
    };
};