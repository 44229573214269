import React from 'react';
import { Box, Text, VStack, useColorMode } from '@chakra-ui/react';
import { useCurrentColor } from '../contexts/ColorContext';

const Footer: React.FC = () => {
  const { colorMode } = useColorMode();
  const { primaryColor, secondaryColor, textColor } = useCurrentColor();

  return (
    <Box 
      as="footer" 
      bg={secondaryColor} 
      color={textColor} 
      py={8}
      borderTop="1px solid"
      borderColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
    >
      <VStack spacing={2} textAlign="center">
        <Text>© 2024 (주)지지아이. All rights reserved.</Text>
        <Text>주소: 경기도 김포시 월곶면 애기봉로 468</Text>
        <Text>전화: 02-1800-7631 | 이메일: ggigagu@naver.com</Text>
      </VStack>
    </Box>
  );
};

export default Footer;