import React, { useEffect, useRef } from 'react';
import { Box, BoxProps, Text, VStack, Image, Flex, useColorModeValue, Container } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { useCurrentColor } from '../contexts/ColorContext';

const MotionBox = motion(Box);

const AnimatedSection: React.FC<{ children: React.ReactNode; bg?: string }> = ({ children, bg }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
        controls.start({ opacity: 1, y: 0 });
        }
    }, [controls, inView]);

    return (
        <MotionBox
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
        transition={{ duration: 0.5 }}
        py={20}
        bg={bg}
        >
        <Container maxW="container.xl">
            {children}
        </Container>
        </MotionBox>
    );
};

const GradientText: React.FC<{ children: React.ReactNode; fontSize?: string } & BoxProps> = ({ children, fontSize = "4xl", ...props }) => {
    const { gradientColor } = useCurrentColor();
    return (
      <Text
        fontSize={fontSize}
        fontWeight="bold"
        bgGradient={gradientColor}
        bgClip="text"
        {...props}
      >
        {children}
      </Text>
    );
  };

const AboutPage: React.FC = () => {
    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'gray.100');
    const { secondaryColor } = useCurrentColor();

    return (
        <Box bg={bgColor} color={textColor}>
          <VStack spacing={0} align="stretch">
            {/* Hero Section */}
            <Box h="100vh" position="relative" overflow="hidden">
              <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" textAlign="center">
                <GradientText fontSize="6xl" mb={4}>GGI (지지아이)</GradientText>
                <Text fontSize="xl">2000년부터 혁신적인 가구 솔루션</Text>
              </Box>
            </Box>
    
            {/* History Section */}
            <AnimatedSection>
              <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between">
                <Box flex={1} mr={{ base: 0, md: 8 }} mb={{ base: 8, md: 0 }}>
                  <GradientText fontSize="4xl" mb={4}>우리의 여정</GradientText>
                  <Text fontSize="xl" mb={4}>2000년에 시작된 우리의 작은 시작으로부터 GGI는 혁신적인 가구 솔루션의 선두주자로 성장했습니다.</Text>
                  <VStack align="start" spacing={4}>
                    <Text>2000: GGI 설립</Text>
                    <Text>2001: 첫 번째 사무용 가구 생산</Text>
                    <Text>2002: 학교 가구로 확장</Text>
                  </VStack>
                </Box>
                <Image src='assets/history-image.webp' alt="GGI History" objectFit="cover" w={{ base: "100%", md: "45%" }} borderRadius="lg" />
              </Flex>
            </AnimatedSection>
    
            {/* Female-Owned Business Introduction */}
            <AnimatedSection>
              <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between">
                <Image src='/assets/female-ceo.webp' alt="Female Representative" objectFit="cover" w={{ base: "100%", md: "45%" }} borderRadius="lg" mb={{ base: 8, md: 0 }} />
                <VStack align="start" spacing={4} flex={1} ml={{ base: 0, md: 8 }}>
                  <GradientText fontSize="4xl" mb={4}>여성 기업</GradientText>
                  <Text fontSize="xl">GGI는 여성 기업으로서, 창립 이래로 혁신과 다양성을 촉진하며 지속적인 성장을 이루고 있습니다.</Text>
                  <Text fontSize="lg">우리의 대표는 가구 업계에서 오랜 경험과 통찰력을 가지고 있으며, 이를 통해 GGI가 전국적으로 인정받는 브랜드로 성장할 수 있었습니다.</Text>
                </VStack>
              </Flex>
            </AnimatedSection>
    
            {/* Certifications Section */}
            <AnimatedSection bg={secondaryColor}>
              <Box textAlign="center" mb={8}>
                <GradientText fontSize="4xl">우리의 성과</GradientText>
              </Box>
              <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between">
                <Image src='/assets/certification-image.webp' alt="GGI Certifications" objectFit="cover" w={{ base: "100%", md: "45%" }} borderRadius="lg" mb={{ base: 8, md: 0 }} />
                <VStack align="start" spacing={4} flex={1} ml={{ base: 0, md: 8 }}>
                  <Text fontSize="xl">품질에 대한 우리의 헌신을 보여주는 인증들을 자랑스럽게 생각합니다:</Text>
                  <Text>2024.05.23: 직접 생산 증명서 (사무용 가구)</Text>
                  <Text>2024.05.23: 직접 생산 증명서 (교실 가구)</Text>
                  <Text>2024.05.23: 직접 생산 증명서 (칠판 보조장)</Text>
                  <Text>2024.05.30: 여성 소유 기업 증명서</Text>
                </VStack>
              </Flex>
            </AnimatedSection>
    
            {/* Procurement MAS System Introduction */}
            <AnimatedSection>
              <Box textAlign="center">
                <GradientText fontSize="4xl" mb={4}>조달 MAS제도 소개</GradientText>
                <Text fontSize="xl">GGI는 조달 MAS제도에 적극 참여하고 있으며, 이를 통해 다양한 공공기관에 고품질의 가구를 공급하고 있습니다.</Text>
                <Text fontSize="lg" mt={4}>조달 MAS제도는 공공기관과 민간 기업 간의 협력을 촉진하는 중요한 프로그램으로, GGI는 이 제도를 통해 전국적으로 인정받는 가구 솔루션을 제공합니다.</Text>
              </Box>
            </AnimatedSection>
    
            {/* Vision Section */}
            <AnimatedSection>
              <Box textAlign="center">
                <GradientText fontSize="4xl" mb={4}>우리의 비전</GradientText>
                <Text fontSize="xl">GGI는 전국의 사무실과 학교에서 생산성과 편안함을 향상시키는 혁신적인 가구 솔루션을 창출하기 위해 노력하고 있습니다.</Text>
              </Box>
            </AnimatedSection>
          </VStack>
        </Box>
    );
};

export default AboutPage;