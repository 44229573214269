import React, { useState, useEffect } from 'react';
import { Box, useColorMode, Text, VStack } from '@chakra-ui/react';

import { useCurrentColor } from '../contexts/ColorContext';

const HomePage: React.FC = () => {

  const lightBg = '/assets/background-light.webp';
  const darkBg = '/assets/background-dark.webp';

  const { colorMode } = useColorMode();
  const [bgImage, setBgImage] = useState(colorMode === 'light' ? lightBg : darkBg);

  const { primaryColor, secondaryColor, textColor } = useCurrentColor();

  useEffect(() => {
      const timer = setTimeout(() => {
        setBgImage(colorMode === 'light' ? lightBg : darkBg);
      }, 50); // 약간의 지연을 줌으로써 트랜지션이 적용될 수 있게 합니다.

      return () => clearTimeout(timer);
  }, [colorMode]);

  return (
    <Box
      minHeight="100vh"
      position="relative"
      overflow="hidden"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundImage={`url(${bgImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
        transition="opacity 0.5s ease-in-out, transform 0.5s ease-in-out"
        opacity={1}
        transform="scale(1)"
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${colorMode === 'light' ? lightBg : darkBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0,
          transition: 'opacity 0.5s ease-in-out',
        }}
        sx={{
          '&._changing': {
            opacity: 0,
            transform: 'scale(1.05)',
            _before: {
              opacity: 1,
            },
          },
        }}
        className={bgImage !== (colorMode === 'light' ? lightBg : darkBg) ? '_changing' : ''}
        zIndex={-1}
      />
      <Box
        minHeight="100vh"
        position="relative"
        zIndex={1}
      >
        <VStack 
          spacing={4} 
          // align="stretch" 
          p={8} 
        >
            <Box
                // bg="whiteAlpha.900"
                bg={primaryColor}
                p={8}
                w='lg'
                borderRadius="16px"
                boxShadow="md"
                textAlign="center"
                opacity={0.95}
                
            >
                <Text fontSize="4xl" fontWeight="bold" color={textColor}>Welcome to GGI</Text>
                <Text fontSize="xl">Providing the Best Office & School Furniture</Text>
            </Box>
          {/* 추가 콘텐츠... */}
        </VStack>
      </Box>
    </Box>
  );
};

export default HomePage;