import React, { useEffect } from 'react';
import { Box, Heading, VStack, Text, Divider } from '@chakra-ui/react';

import { getNewsNoAuth } from '../apis/api';

interface NewsItem {
  title: string;
  date: string;
  content: string;
}

// const newsItems: NewsItem[] = [
//   {
//     title: "GGI, 2024년 신제품 라인업 발표",
//     date: "2024-07-15",
//     content: "지지아이(GGI)가 2024년 하반기 신제품 라인업을 발표했습니다. 이번 신제품은 사무실과 학교 환경의 변화에 맞춰 개발되었으며, 특히 원격 작업과 학습을 지원하는 기능이 강화되었습니다."
//   },
//   {
//     title: "지지아이, 환경 친화적 가구 생산 확대",
//     date: "2024-06-20",
//     content: "지지아이가 환경 보호에 앞장서기 위해 친환경 소재를 사용한 가구 생산을 확대하기로 결정했습니다. 이는 지속 가능한 미래를 위한 회사의 노력의 일환입니다."
//   },
//   {
//     title: "2024 교육 가구 박람회 참가 성료",
//     date: "2024-05-10",
//     content: "지지아이가 2024 교육 가구 박람회에 참가하여 큰 호응을 얻었습니다. 특히 새로 선보인 스마트 교실 솔루션이 많은 관심을 받았습니다."
//   }
// ];

const NewsPage: React.FC = () => {

  const [newsItems, setNewsItems] = React.useState<NewsItem[]>([]);

  const fetchNews = async() => {
    const data = await getNewsNoAuth();
    console.log(data);
    if (data.length === 0) {
      console.error('Error fetching News');
      setNewsItems([]);
    } else {
      setNewsItems(data);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);
  

  return (
    <Box 
        maxW="container.xl" 
        mx="auto" 
        mt={8}
        minHeight="100vh"
        position="relative"
        overflow="hidden"
    >
      <Heading as="h1" size="2xl" mb={6}>Latest News</Heading>
      <VStack spacing={6} align="stretch">
        {newsItems.map((item, index) => (
          <Box key={index}>
            <Heading as="h2" size="lg">{item.title}</Heading>
            <Text color="gray.500" mb={2}>{item.date}</Text>
            <Text>{item.content}</Text>
            {index < newsItems.length - 1 && <Divider mt={4} />}
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default NewsPage;