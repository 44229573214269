import React from 'react';
import { useCallback } from 'react';
import { ColorModeScript, useToast } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ColorProvider } from './contexts/ColorContext';
import { AuthProvider } from './contexts/AuthContext';

import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ProductPage from './pages/ProductPage';
import NewsPage from './pages/NewsPage';
import ContactPage from './pages/ContactPage';
import HelpPage from './pages/HelpPage';
import AdminPage from './pages/AdminPage';
import SignInPage from './pages/SignInPage';


const AppContent: React.FC = () => {
  
    return (
        <Router>
            <Header/>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/products" element={<ProductPage />} />
                <Route path="/news" element={<NewsPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/help" element={<HelpPage />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/signin" element={<SignInPage />} />
            </Routes>
            <Footer />
        </Router>
    );
};

const App: React.FC = () => {

  return (
    <>
      <ColorModeScript initialColorMode="light" />
      <ColorProvider>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </ColorProvider>
    </>
  );
};

export default App;