import React from 'react';
import { useEffect } from 'react';
import { Box, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text } from '@chakra-ui/react';

import { getFAQsNoAuth } from '../apis/api';

interface FAQItem {
  question: string;
  answer: string;
}

// const faqItems: FAQItem[] = [
//   {
//     question: "주문한 제품은 언제 배송되나요?",
//     answer: "주문 확인 후 3-5 영업일 내에 배송이 시작됩니다. 대량 주문이나 맞춤 제작의 경우 더 오래 걸릴 수 있습니다."
//   },
//   {
//     question: "제품에 대한 A/S는 어떻게 받을 수 있나요?",
//     answer: "제품 구매 후 1년간 무상 A/S가 제공됩니다. A/S 신청은 고객센터(02-1800-7631)로 연락 주시면 친절히 안내해 드리겠습니다."
//   },
//   {
//     question: "맞춤 제작도 가능한가요?",
//     answer: "네, 가능합니다. 특별한 요구사항이 있으시면 연락 주시기 바랍니다. 담당자가 상담을 통해 최적의 솔루션을 제안해 드리겠습니다."
//   },
//   {
//     question: "제품 반품 및 교환 정책은 어떻게 되나요?",
//     answer: "제품 수령 후 7일 이내에 반품 및 교환이 가능합니다. 단, 사용하지 않은 제품에 한하며, 맞춤 제작 상품의 경우 반품이 어려울 수 있습니다."
//   }
// ];




const HelpPage: React.FC = () => {

  const [faqItems, setFAQItems] = React.useState<FAQItem[]>([]);

  const fetchNews = async() => {
    const data = await getFAQsNoAuth();
    console.log(data);
    if (data.length === 0) {
      console.error('Error fetching News');
      setFAQItems([]);
    } else {
      setFAQItems(data);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);


  return (
    <Box 
        maxW="container.xl" 
        mx="auto" 
        mt={8}
        minHeight="100vh"
        position="relative"
        overflow="hidden"
    >
      <Heading as="h1" size="2xl" mb={6}>Help & FAQ</Heading>
      <Accordion allowToggle>
        {faqItems.map((item, index) => (
          <AccordionItem key={index}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text fontWeight="bold">{item.question}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {item.answer}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default HelpPage;