import React, { useState } from 'react';
import { Box, Heading, VStack, FormControl, FormLabel, Input, Textarea, Button, useToast } from '@chakra-ui/react';
import { sendContactMessage } from '../apis/api';
import { useNavigate } from 'react-router-dom';

const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const toast = useToast();
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendContactMessage(formData);
      toast({
        title: "Message sent successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Reset form
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      // Redirect to home page after a short delay
      setTimeout(() => {
        navigate('/');
      }, 1000);
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: "Failed to send message",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="container.xl" mx="auto" mt={8} minHeight="100vh" position="relative" overflow="hidden">
      <Heading as="h1" size="2xl" mb={6}>Contact Us</Heading>
      <VStack as="form" spacing={4} align="stretch" onSubmit={handleSubmit}>
        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input name="name" placeholder="Your name" value={formData.name} onChange={handleInputChange} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input name="email" type="email" placeholder="Your email" value={formData.email} onChange={handleInputChange} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Subject</FormLabel>
          <Input name="subject" placeholder="Subject" value={formData.subject} onChange={handleInputChange} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Message</FormLabel>
          <Textarea name="message" placeholder="Your message" value={formData.message} onChange={handleInputChange} />
        </FormControl>
        <Button type="submit" colorScheme="blue">Send Message</Button>
      </VStack>
    </Box>
  );
};

export default ContactPage;