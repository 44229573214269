import React, { useEffect, useState } from 'react';

// import blackboardCabinetImg from '../assets/products/blackboard-cabinet.webp';
// import bookshelfImg from '../assets/products/bookshelf.webp';
// import bedImg from '../assets/products/bed.webp';
// import coffeeTableImg from '../assets/products/coffee-table.webp';
// import lockerImg from '../assets/products/locker.webp';
// import militaryLockerImg from '../assets/products/military-locker.webp';
// import cabinetImg from '../assets/products/cabinet.webp';
// import deskImg from '../assets/products/desk.webp';

import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  Card,
  CardHeader,
  CardBody,
  Stack,
  StackDivider,
  Image
} from '@chakra-ui/react';

import { getProductsNoAuth } from '../apis/api';

interface Product {
  name: string;
  description: string;
  features: string[];
  photo_path: string;
}

// const products: Product[] = [
//     {
//       name: '회의용탁자',
//       description: '효율적인 회의 진행을 위한 넓고 편안한 회의용 탁자입니다.',
//       features: ['넓은 작업 공간', '케이블 관리 시스템', '모듈식 디자인'],
//       image: '/assets/products/conference-table.webp'
//     },
//     {
//       name: '칠판보조장',
//       description: '교실 환경에 최적화된 칠판 보조 수납장입니다.',
//       features: ['넉넉한 수납 공간', '견고한 재질', '이동이 용이한 디자인'],
//       image: '/assets/products/conference-table.webp'
//     },
//     {
//       name: '책장',
//       description: '다양한 크기의 책을 효율적으로 보관할 수 있는 책장입니다.',
//       features: ['조절 가능한 선반', '안정적인 구조', '다양한 크기 옵션'],
//       image: '/assets/products/conference-table.webp'
//     },
//     {
//       name: '침대',
//       description: '편안한 휴식을 위한 고품질 침대입니다.',
//       features: ['인체공학적 설계', '내구성 있는 프레임', '다양한 사이즈'],
//       image: '/assets/products/conference-table.webp'
//     },
//     {
//       name: '응접탁자',
//       description: '고급스러운 디자인의 응접실용 탁자입니다.',
//       features: ['세련된 디자인', '내구성 높은 소재', '쉬운 관리'],
//       image: '/assets/products/conference-table.webp'
//     },
//     {
//       name: '사물함',
//       description: '학교나 사무실에서 개인 물품을 안전하게 보관할 수 있는 사물함입니다.',
//       features: ['튼튼한 잠금장치', '내구성 있는 재질', '다양한 크기 옵션'],
//       image: '/assets/products/conference-table.webp'
//     },
//     {
//       name: '군용사물함',
//       description: '군부대 환경에 적합한 견고하고 안전한 사물함입니다.',
//       features: ['높은 보안성', '내습성 강한 재질', '대용량 수납'],
//       image: '/assets/products/conference-table.webp'
//     },
//     {
//       name: '캐비닛',
//       description: '다용도로 사용 가능한 수납 캐비닛입니다.',
//       features: ['다양한 수납 공간', '견고한 구조', '모던한 디자인'],
//       image: '/assets/products/conference-table.webp'
//     },
//     {
//       name: '책상',
//       description: '효율적인 작업을 위한 현대적인 디자인의 책상입니다.',
//       features: ['넓은 작업면', '케이블 관리 시스템', '인체공학적 설계'],
//       image: '/assets/products/conference-table.webp'
//     }
//   ];
  
  const ProductPage: React.FC = () => {

    const [products, setProducts] = React.useState<Product[]>([]);

    const fetchNews = async() => {
      const data = await getProductsNoAuth();
      console.log(data);
      if (data.length === 0) {
        console.error('Error fetching News');
        setProducts([]);
      } else {
        setProducts(data);
      }
    };

    useEffect(() => {
      fetchNews();
    }, []);


    return (
      <Box
        maxW="container.xl"
        mx="auto"
        mt={8}
        minHeight="100vh"
        position="relative"
        overflow="hidden"
        p={4}
      >
        <Heading as="h1" size="2xl" mb={6}>Our Products</Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {products.map((product, index) => (
            <Card key={index}>
              <CardHeader>
                <Heading size='md'>{product.name}</Heading>
              </CardHeader>
              <CardBody>
                <Image
                  src={product.photo_path}
                  alt={product.name}
                  borderRadius="lg"
                  mb={4}
                  fallbackSrc="https://via.placeholder.com/300x200?text=Product+Image"
                />
                <Stack divider={<StackDivider />} spacing='4'>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Description
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {product.description}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Features
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {product.features.join(', ')}
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
    );
  };
  
  export default ProductPage;
  